<template>
    <Layout>
        <div class="container-exercises">
            <BreadCrumb v-if="!getIsMobile" :items="[
                { name: 'Banco de exercícios', path: '/exercicios/fisica' },
                { name: getSelectedCategory?.name, path: `/exercicios/${getSelectedCategory?.url.toLowerCase()}` },
                { name: getSubCategory?.sub_area.name, path: `/exercicios/${getSelectedCategory?.url.toLowerCase()}` },
                { name: getSubCategory?.name, path: `/exercicios/${getSelectedCategory?.url.toLowerCase()}/${getSubCategory?.url.toLowerCase()}` },
                { name: 'Exercício', path: `#` },
            ]" />
            <div class="page-header">
                <router-link
                    :to="`/exercicios/${getSelectedCategory?.url.toLowerCase()}/${getSubCategory?.url.toLowerCase()}?refresh=${refresh}`">
                    <IconSax type="outline" name="arrow-left" />
                </router-link>
            </div>
            <Exercise :getSelectedExercise="getSelectedExercise" />
        </div>
    </Layout>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Layout: () =>
            import(
                "@/layout/Layout.vue"
            ),
        BreadCrumb: () =>
            import(
                "@/components/common/Breadcrumb.vue"
            ),
        Exercise: () =>
            import(
                "@/components/exercises/Exercise.vue"
            ),
    },
    computed: {
        ...mapGetters({
            getIsMobile: "layout/getIsMobile",
            getSelectedCategory: "exercises/getSelectedCategory",
            getSubCategory: "exercises/getSubCategory",
            getSelectedExercise: "exercises/getSelectedExercise",
        }),
        exercisesAllowed() {
            if (
                this.$store.getters['user/metasSite'] &&
                this.$store.getters['user/metasSite'].exercises_allowed
            ) {
                return this.$store.getters['user/metasSite'].exercises_allowed === "1";
            } else return null;
        },
    },
    async created() {
        if (!this.exercisesAllowed) {
            this.$router.push({
                name: "Home",
            });
        }
    },
    data() {
        return {
            refresh: false,
        }
    },
    methods: {
        ...mapActions({
            actionFetchExercise: "exercises/actionFetchExercise",
        }),
    },
};
</script>
  
<style lang="scss" scoped>
.container-exercises {
    padding-top: 1.5rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 8.31rem;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }
}
.page-header {
    @include flex-row;
    justify-content: space-between;
    align-items: center;

    margin-top: 2.5rem;

    @media screen and (max-width: 768px) {
        margin-top: 1.5rem;
    }

    ::v-deep .svgCss {
        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        svg path {
            fill: var(--fontcolor) !important;
        }
    }

}
</style>
  