var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"container-exercises"},[(!_vm.getIsMobile)?_c('BreadCrumb',{attrs:{"items":[
            { name: 'Banco de exercícios', path: '/exercicios/fisica' },
            { name: _vm.getSelectedCategory?.name, path: `/exercicios/${_vm.getSelectedCategory?.url.toLowerCase()}` },
            { name: _vm.getSubCategory?.sub_area.name, path: `/exercicios/${_vm.getSelectedCategory?.url.toLowerCase()}` },
            { name: _vm.getSubCategory?.name, path: `/exercicios/${_vm.getSelectedCategory?.url.toLowerCase()}/${_vm.getSubCategory?.url.toLowerCase()}` },
            { name: 'Exercício', path: `#` },
        ]}}):_vm._e(),_c('div',{staticClass:"page-header"},[_c('router-link',{attrs:{"to":`/exercicios/${_vm.getSelectedCategory?.url.toLowerCase()}/${_vm.getSubCategory?.url.toLowerCase()}?refresh=${_vm.refresh}`}},[_c('IconSax',{attrs:{"type":"outline","name":"arrow-left"}})],1)],1),_c('Exercise',{attrs:{"getSelectedExercise":_vm.getSelectedExercise}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }